<!--
 * @Author: Libra
 * @Date: 2021-06-18 14:01:56
 * @LastEditTime: 2023-05-10 14:30:47
 * @LastEditors: Libra
 * @Description: Q&A页面
 * @FilePath: /stone-exam-ui/src/views/QAClient.vue
-->
<template>
  <div class="QA-container">
    <simple-header :hasRight="false" />
    <div class="title">在线考试 常见问题回答</div>
    <el-tabs
      type="border-card"
      stretch
      v-model="activeName"
      @tab-click="handleClick"
    >
      <el-tab-pane
        :label="item.title"
        :name="item.title"
        v-for="(item, index) in QA"
        :key="index"
      >
        <div class="questions" v-for="(i, idx) in item.question" :key="idx">
          <div class="question"><i class="ball red"></i>{{ i.Q }}</div>
          <div class="answer"><i class="ball blue"></i>{{ i.A }}</div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import SimpleHeader from '@/components/SimpleHeader'
import QA from '@/common/qaclient.js'
export default {
  components: {
    SimpleHeader
  },
  data() {
    return {
      QA: QA,
      activeName: '系统使用问题'
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #cb2a1d;
}
::v-deep .el-tabs {
  background-color: rgb(250, 250, 250);
  width: 1240px;
  box-sizing: border-box;
}
::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #cb2a1d;
}
::v-deep .el-tabs__active-bar {
  background-color: #cb2a1d;
}
.QA-container {
  padding: 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .title {
    line-height: 100px;
    font-size: 28px;
    font-weight: 500;
    text-align: center;
  }
  .questions {
    line-height: 2;
    margin-top: 40px;
    padding: 0 20px;
  }
  .questions .ball {
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  .questions .red {
    background-color: #d6221a;
  }
  .questions .blue {
    background-color: #5898f3;
  }
}
</style>
