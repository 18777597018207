var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "QA-container" },
    [
      _c("simple-header", { attrs: { hasRight: false } }),
      _c("div", { staticClass: "title" }, [_vm._v("在线考试 常见问题回答")]),
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card", stretch: "" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        _vm._l(_vm.QA, function (item, index) {
          return _c(
            "el-tab-pane",
            { key: index, attrs: { label: item.title, name: item.title } },
            _vm._l(item.question, function (i, idx) {
              return _c("div", { key: idx, staticClass: "questions" }, [
                _c("div", { staticClass: "question" }, [
                  _c("i", { staticClass: "ball red" }),
                  _vm._v(_vm._s(i.Q)),
                ]),
                _c("div", { staticClass: "answer" }, [
                  _c("i", { staticClass: "ball blue" }),
                  _vm._v(_vm._s(i.A)),
                ]),
              ])
            }),
            0
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }